<template>
  <el-button
    :disabled="useDisabled"
    :size="size"
    :type="type"
    :loading="showLoading"
    @click="submit"
  >
    <slot />
  </el-button>
</template>

<script setup>
import { ref, getCurrentInstance, computed, onMounted } from "vue";

const emits = defineEmits(["submit", "handleClick"]);

const props = defineProps({
  size: {
    type: String,
    default: "default",
  },
  type: {
    type: String,
    default: "primary",
  },
  // 使用加载
  useLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // 节流 延迟
  bypassDelay: {
    type: Number,
    default: 500,
  },
});

const { proxy } = getCurrentInstance();

/* 属性 */
const useDisabled = computed(() => props.disabled);

// 加载状态
const showLoading = ref(false);
// 打开
const openLoading = () => {
  showLoading.value = true;
};
// 关闭
const closeLoading = () => {
  showLoading.value = false;
};

const submit = proxy.$bypass((e) => {
  // 使用
  if (props.useLoading) {
    if (showLoading.value) {
      return;
    }

    openLoading();

    emits("submit", () => {
      closeLoading();
    });
  } else {
    emits("handleClick");
  }
}, props.bypassDelay);

// 递归查找 BUTTON
const recursiveLookupButton = (el) => {
  if (el.nodeName == "BUTTON") {
    return el;
  }

  return recursiveLookupButton(el.parentElement);
};

defineExpose({
  openLoading,
  closeLoading,
});
</script>

<style lang="scss"></style>
