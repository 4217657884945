import piniaStore from "@/piniaStore/index";
import { STORE_NAME_AUTH_TOKEN } from "@/utils/storeAgeTables";
// ****************************************************************
// 校验 模块 START
// ****************************************************************
// 缺省
export const isEmpty = (val) => ["", undefined, null].includes(val);

// 判断数据是否有值 数组、普通对象、字符值
export const isHasValue = (currData = "") => {
  // 是否存在Object原型对象
  if (currData instanceof Object) {
    // 是否是数组 []
    if (Array.isArray(currData)) {
      return !!currData.length;
    } else {
      // 标准对象 {}
      const objLen = Object.keys(currData);
      return !!objLen.length;
    }
  } else {
    const isHasVal = !isEmpty(currData);
    // 有值
    if (isHasVal) {
      if (typeof currData == "number") {
        return true;
      } else {
        const joinStr = currData.split(" ").join("");
        return !isEmpty(joinStr);
      }
    } else {
      // 字符串、null、undefined
      return false;
    }
  }
};

// 常规 校验表单
export const testForm = (call, refEl) => {
  return new Promise((resolve) => {
    try {
      refEl.validate((valid, fields) => {
        if (!valid) {
          call ? call() : "";
        }
        resolve(valid);
      });
    } catch (error) {
      console.log(error);
    }
  });
};

// 按属性列表 校验表单
export const testFormProps = async (props = {}, call, refEl) => {
  // 属性对象转数组
  const propsArray = Object.keys(props);

  if (!propsArray.length) {
    return true;
  }

  try {
    await refEl.validateField(propsArray);
    return true;
  } catch (error) {
    call ? call() : "";
    return false;
  }
};

// 获取数据 更新后的数据
export const getDataUpdateAfter = (originData, newData, result) => {
  const typeofOriginData = typeof originData;

  // 字符串
  // console.log(typeofOriginData);
  if (["string", "number"].includes(typeofOriginData)) {
    // 有值
    if (!isEmpty(originData) && !isEmpty(newData)) {
      //值 不相等
      if (originData != newData) {
        result = newData;
      }
    } else {
      //值 不相等
      if (originData !== newData) {
        result = newData;
      }
    }
  } else {
    // 数组
    const originDataArrayType = Array.isArray(originData);
    const newDataArrayType = Array.isArray(newData);

    // 常规对象
    const originDataObjectType = !Array.isArray(originData);
    const newDataObjectType = !Array.isArray(newData);

    // 常规对象 对比
    if (originDataObjectType && newDataObjectType) {
      result = {};
      // console.log("常规对象 对比");
      // console.log(originDataObjectType, newDataObjectType);

      /* 处理对象 */
      const originProps = Object.getOwnPropertyNames(originData || {});
      const newProps = Object.getOwnPropertyNames(newData || {});

      // console.log(originProps);
      // console.log(newProps);
      // 遍历新数据
      for (let i = 0; i < newProps.length; i++) {
        //属性名
        const origin_propName = originProps[i];
        const new_propName = newProps[i];

        //值
        const origin_value = originData[new_propName];
        const new_value = newData[new_propName];

        // console.log(new_propName);
        //origin 不存在new的 参数
        if (!originData.hasOwnProperty(new_propName)) {
          result[new_propName] = new_value;
        } else {
          //对象属性
          if (origin_value instanceof Object) {
            const comparisonResult = getDataUpdateAfter(
              origin_value || {},
              new_value || {}
            );

            // console.log(comparisonResult);
            // 非数组
            if (!Array.isArray(comparisonResult)) {
              const resultLength = Object.keys(comparisonResult).length;

              // 如果存在值这说明不相同
              if (resultLength) {
                result[new_propName] = comparisonResult;
              }
            }

            // 数组
            if (Array.isArray(comparisonResult)) {
              // console.log(origin_propName);
              // console.log(new_propName);
              // console.log(origin_value);
              // console.log(new_value);
              // console.log(comparisonResult);
              // 有变化
              if (comparisonResult.length) {
                result[new_propName] = comparisonResult;
              } else {
                // 对比后 无变化

                // 原数据有值 && 新数据无值
                if (origin_value.length && !new_value.length) {
                  result[new_propName] = [];
                }
              }
            }
          } else {
            // 有值
            if (!isEmpty(origin_value) && !isEmpty(new_value)) {
              //值 不相等
              if (origin_value != new_value) {
                result[new_propName] = new_value;
              }
            } else {
              //值 不相等
              if (origin_value !== new_value) {
                result[new_propName] = new_value;
              }
            }
          }
        }
      }
      // console.log(originDataObjectType, newDataObjectType);
      // console.log("常规对象 对比", result);
      // console.log(originData);
      // console.log(newData);
    } else if (originDataArrayType && newDataArrayType) {
      //数组 对比
      result = [];
      // console.log("常规数组 对比");
      // console.log(originDataArrayType, newDataArrayType);

      const originArrayLength = originData.length;
      const newArrayLength = newData.length;

      // console.log(originArrayLength);
      // console.log(newArrayLength);
      // 对比长度
      if (originArrayLength === newArrayLength) {
        let isPass = true;
        for (let i = 0; i < originData.length; i++) {
          let origin_item = originData[i];
          let new_item = newData[i];
          // console.log(origin_item);
          // console.log(new_item);
          const comparisonResult = getDataUpdateAfter(origin_item, new_item);
          if (!isEmpty(comparisonResult)) {
            // 有效值
            if (typeof comparisonResult != "object") {
              isPass = false;
            } else {
              if (!Array.isArray(comparisonResult)) {
                const resultLength = Object.keys(comparisonResult).length;

                // 如果存在值这说明不相同
                if (resultLength) {
                  isPass = false;
                }
              }

              if (Array.isArray(comparisonResult)) {
                if (comparisonResult.length) {
                  isPass = false;
                }
              }
            }
          }
        }

        // 不通过
        if (!isPass) {
          // 赋值对象数据
          result = newData;
          console.log("常规数组 对比 不通过 赋值替换");
        }
      } else {
        console.log("常规数组 对比 长度不一致 赋值替换");
        result = newData;
      }
    } else {
      console.log("常规对象、数组 对比 类型不一致 赋值替换");
      result = newData;
    }
  }

  return result;
};

// ****************************************************************
// 校验 模块 END
// ****************************************************************

// ******************************************************************
// 基础处理 START
// ******************************************************************
// 处理请求响应数据
export const storeResponse = (res, call, isCustomUrl) => {
  if (res && res.code === 200) {
    if (call) {
      call();
    }
    return res;
  } else {
    // 是否是自定义路径
    if (isCustomUrl) {
      return res;
    } else {
      if (res && res.code) {
        return Promise.reject(res);
      } else {
        return Promise.reject(false);
      }
    }
  }
};

// 处理对象转 路径携带参数字符串
export const paramToStr = (data) => {
  if (!data) {
    return "";
  }

  return Object.keys(data)
    .map((item) => `${item}=${data[item]}`)
    .join("&");
};

// 设置缓存
export const setItem = (name, data) => {
  window.localStorage.setItem(name, data);
};

// 获取缓存
export const getItem = (name) => {
  return window.localStorage.getItem(name);
};

// 获取本地 Token
export const getStoreAgeToken = () => getItem(STORE_NAME_AUTH_TOKEN);

// 清除本地 授权Token
export const clearStoreAgeToken = () => {
  setItem(STORE_NAME_AUTH_TOKEN, "");
};

// 提示操作
// 成功提示
export const successMsg = function (msg) {
  this.$message.success(msg);
};
// 异常提示
export const warningMsg = function (msg) {
  this.$message.warning(msg);
};

/* vuex */
// dispatch调用
export const storeDispatch = function () {
  return this.$store.dispatch(...arguments);
};
// commit调用
export const storeCommit = function (name, params) {
  return this.$store.commit(name, params);
};
// getters调用
export const storeGetters = function (name, params) {
  return this.$store.getters[name];
};

// 处理数字
export const thousandsPrice = (price = 0, opt) => {
  const { isToFixedNum = false, toFixedNum = 2 } = opt || {};
  price = price || 0;
  const number_price = isToFixedNum ? price : Number(price).toFixed(toFixedNum);
  const string_price = number_price.toString();
  const floatIndex = string_price.indexOf(".");
  let integer_num = string_price;
  let float_num = "";
  if (floatIndex !== -1) {
    const floatRes = string_price.slice(0, floatIndex);
    integer_num = floatRes;
    float_num = string_price.slice(floatIndex);
  }

  /**
   * 第一次reverse 把数字排序改为倒序 1234=4321
   * 第二次reverse 把截取分组排序纠正 [[432],[1]] = [[1],[432]]
   * 第三次reverse 把截取分组里面的数字排序纠正 [1][432] = [1][234]
   */
  // 分组
  const map_pirce = integer_num
    .split("")
    .reverse()
    .reduce((add, curr, index) => {
      const addLen = add.length;
      if (index % 3) {
        add[addLen - 1].push(curr);
      } else {
        add.push([curr]);
      }
      return add;
    }, [])
    .reverse()
    .map((d) => d.reverse().join(""));

  const result = map_pirce.join(",") + float_num;
  return result;
};

// 解绑上下文
export const removeDecontextAttr = (data = {}) => {
  return JSON.parse(JSON.stringify(data));
};

// 根据值查询options列表项
export const getOptsItem = function (value, options = [], config) {
  const { valueKey = "value", returnKey = "label" } = config || {};
  const item = options.find((item) => item[valueKey] == value) || {};

  if (returnKey == "object") {
    return item;
  } else {
    return item[returnKey] || "";
  }
};

/* 对字符串或 数组string[] 进行去重操作 并 转为字符串 */
export const getNamesDeleteTepeat = (result, opts) => {
  const { splitBar = ",", joinBar = "、" } = opts || {};

  const result_type = typeof (result || "");

  let str_arr = result;

  // 字符串
  if (result_type === "string") {
    str_arr = (result || "").split(splitBar);
  }

  return str_arr
    .reduce((add, curr, index) => {
      if (!add.includes(curr)) {
        add.push(curr);
      }
      return add;
    }, [])
    .join(joinBar);
};

//
/**
 * 赋值参数
 * @params {object} origin 原数据（被动更新）弹夹
 * @params {object} data 常规数据（填充原数据）子弹
 * @params {object} config
 * @param {object} config.ignoreNull 不赋值 data 中的 空数据
 * @param {object} config.ignoreOriginNull 不赋值 origin 中的 空数据
 * @param {object} config.updateOriginDataType 更新的原数据 类型 origin|data
 */
export const updateParams = (origin = {}, data = {}, config) => {
  const {
    ignoreNull = false,
    ignoreOriginNull = false,
    updateOriginDataType = "origin",
  } = config || {};
  const jsonData = JSON.parse(JSON.stringify(data));

  // 遍历的数据源
  const useForData = updateOriginDataType == "origin" ? origin : data;

  for (let i in useForData) {
    // 待赋值数据
    const result = jsonData[i];
    // 原数据的 参数数据
    const originResult = useForData[i];

    if (ignoreNull || ignoreOriginNull) {
      // 忽略 data 中的 空值
      if (ignoreNull) {
        // origin[i] = isEmpty(result) ? originResult : result;
        if (isEmpty(result)) {
          origin[i] = originResult;
        } else {
          if (result instanceof Object) {
            if (Array.isArray(result)) {
              origin[i] = result.length ? result : originResult;
            } else {
              origin[i] = Object.keys(result).length ? result : originResult;
            }
          } else {
            origin[i] = result;
          }
        }
      }

      // 忽略 origin 中的 空值
      if (ignoreOriginNull) {
        // origin[i] = isEmpty(origin[i]) ? origin[i] : result;

        if (isEmpty(origin[i])) {
          origin[i] = origin[i];
        } else {
          if (origin[i] instanceof Object) {
            if (Array.isArray(origin[i])) {
              origin[i] = origin[i].length ? result : origin[i];
            } else {
              origin[i] = Object.keys(origin[i]).length ? result : origin[i];
            }
          } else {
            origin[i] = result;
          }
        }
      }
    } else {
      // origin[i] = isEmpty(result) ? "" : result;
      if (isEmpty(result)) {
        origin[i] = "";
      } else {
        if (result instanceof Object) {
          if (Array.isArray(result)) {
            origin[i] = result.length ? result : [];
          } else {
            origin[i] = Object.keys(result).length ? result : {};
          }
        } else {
          origin[i] = result;
        }
      }
    }
  }
  // console.log(origin);
};

// 节流
export const bypass = function (fun, delay = 500) {
  // 延迟时间
  let toTimeId = null;
  // 是否拦截
  let isStop = false;
  return function () {
    if (isStop) {
      return;
    }
    isStop = true;

    toTimeId = setTimeout(() => {
      // 关闭
      clearTimeout(toTimeId);
      toTimeId = null;
      isStop = false;
    }, delay);
    return fun.apply(fun, arguments);
  };
};

/**
 * 递归查询关键词索引 操作数据
 * @params {object} originData
 * @params {object} params
 * @params {object} opt
 * @param {object} opt.mode 操作模式 update||add||delete
 *
 */
export const setOperateKeywordSearch = (
  originData,
  params,
  opt,
  callback = () => {}
) => {
  try {
    const {
      tableRef,
      keyword = "id",
      parentKey = "parentId",
      levelKey = "level",
      props = { children: "children" },
      mode = "update",
      tableLazyParentStoreData, //表格懒加载静态缓存
    } = opt || {};

    // console.log(originData);
    // console.log(params);
    // console.log(opt);
    // const originData = tableData.value.result;

    // 检索值
    const keywordValue = params[keyword];
    // 查找 检索查询
    const itemIndex = originData.findIndex((d) => d[keyword] == keywordValue);

    // 修改
    if (mode == "update") {
      // console.log(params);
      // 定位到
      if (itemIndex != -1) {
        const parentId = Number(params[parentKey] || 0);
        //  检索查询 父级所在
        const parentIndex = originData.findIndex((d) => d[keyword] == parentId);

        // delete params[props.children];
        const { children = [], ...otherParam } = params;
        updateParams(originData[itemIndex], otherParam);

        const { id } = originData[itemIndex];

        // 更新当前项
        tableLazyParentStoreData[id] = children;
        originData[itemIndex].children = children;

        // 更新当前在父级的信息
        if (parentIndex != -1) {
          tableLazyParentStoreData[parentId] = originData[itemIndex];
        }
        // console.log(parentId)
        // console.log(originData);
        // console.log(children);
        // console.log(originData[itemIndex]);
        // console.log(tableLazyParentStoreData);
        callback();
        return;
      }
    } else if (mode == "add") {
      const parentId = Number(params[parentKey] || 0);
      // console.log(params);
      // console.log(parentKey);
      // console.log(parentId);

      // 子级
      if (parentId) {
        //  检索查询 父级所在
        const parentIndex = originData.findIndex((d) => d[keyword] == parentId);

        if (parentIndex != -1) {
          console.log("检索到父级");
          // console.log("父级" + parentIndex);
          // 赋值
          originData[parentIndex].hasChildren = true;
          // console.log(params);
          // console.log(originData);
          // console.log(originData[parentIndex]);
          // console.log(tableLazyParentStoreData);
          if ((originData[parentIndex].children || []).length) {
            originData[parentIndex].children.push(params);
          } else {
            originData[parentIndex].children = [params];
          }
          // console.log(originData[parentIndex]);

          // 同步
          if (tableLazyParentStoreData) {
            const newItem = originData[parentIndex];
            const newChildren = newItem.children;
            const thatParentId = newItem[keyword];
            // console.log(thatParentId);
            // console.log(newChildren);
            tableLazyParentStoreData[thatParentId] = newChildren;

            // 更新ref内的store
            tableRef.value.store.states.lazyTreeNodeMap.value[thatParentId] =
              newChildren;
          }
          // console.log(parentId);
          // console.log(originData[parentIndex]);
          // console.log(tableLazyParentStoreData);

          callback();
          return;
        }
      } else {
        console.log("父级0");
        console.log(params);
        // 父级0
        originData.push(params);
        callback();
        return;
      }
    } else if (mode == "delete") {
      // console.log(mode, itemIndex);
      // console.log(originData);
      // 定位到
      if (itemIndex != -1) {
        const delItem = removeDecontextAttr(originData[itemIndex]);
        originData.splice(itemIndex, 1);

        const currParnetId = delItem[parentKey];
        // 处理父级
        // const parentItem = tableLazyParentStoreData[currParnetId];
        // console.log(parentItem);
        // console.log(currParnetId);
        tableLazyParentStoreData[currParnetId] = originData;

        console.log(originData);
        console.log(tableLazyParentStoreData);
        setTimeout(() => {
          // // 重置
          // if (!originData.length) {
          //   tableRef.value.store.states.lazyTreeNodeMap.value[currParnetId] =
          //     [];
          // }

          //
          // 赋值 隐式数据链
          tableRef.value.store.states.lazyTreeNodeMap.value[currParnetId] =
            originData;

          // 无子级
          if (!originData.length) {
            // 关闭 展开
            tableRef.value.store.states.treeData.value[
              currParnetId
            ].expanded = false;

            // 查询 1级层级的索引
            const oneLevelIndex =
              tableRef.value.store.states.data.value.findIndex(
                (d) => d[keyword] == currParnetId
              );

            // 修改 懒加载状态
            tableRef.value.store.states.data.value[
              oneLevelIndex
            ].hasChildren = false;
          }
        });

        callback();
        return;
      }
    }

    // 遍历
    // console.log("遍历", originData);
    for (let i = 0; i < originData.length; i++) {
      const item = originData[i];
      const children = item[props.children] || [];
      // console.log("递归遍历", children);
      setOperateKeywordSearch(children, params, opt, callback);
    }
  } catch (error) {
    console.log(error);

    return {};
  }
};

// 保留数字小数点后几位小数
export const toFixedSomeNumber = (num = 0, lastIndex = 2, opt) => {
  const num_str = num.toString();
  const reg = new RegExp(`^\\d+(?:\\.\\d{0,${lastIndex}})?`);
  let num_float_str = Number(num_str.match(reg)).toString();

  const {
    // 按需显示
    isDemand = true,
  } = opt || {};

  // 判断小数点
  if (num_float_str.indexOf(".") != -1) {
    let [number = "0", floatNum = "0"] = num_float_str.split(".");
    num_float_str = `${number}.${floatNum.padEnd(2, "0")}`;
  } else {
    const after = new Array(lastIndex).fill(0).join("");
    num_float_str += "." + after;
  }

  if (isDemand) {
    return Number(num_float_str);
  }

  return num_float_str;
};

// ******************************************************************
// 基础处理 END
// ******************************************************************

// ******************************************************************
// 时间处理 START
// ******************************************************************
// 时间

// 转换 补位字符数字
export const numberToAll = (val = "") => {
  const result = val.toString();
  return result.padStart(2, "0");
};

// 兼容ios时间格式转换
export const iosTimeFormat = (dateTime, option) => {
  // isUseDate 用于操作时间值
  const { isUseDate = true } = option || {};
  if (typeof dateTime == "string") {
    const platform = window.navigator.userAgent;
    return platform.indexOf("iPhone") != -1 && isUseDate
      ? dateTime.split("-").join("/")
      : dateTime;
  } else {
    return dateTime;
  }
};

// 时间规则
export const timeRules = (date) => {
  if (isEmpty(date)) {
    return "";
  }

  // 将日期字符串转换为时间戳
  // 指定时间
  const after_time = iosTimeFormat(date); // 兼容ios格式
  const data = new Date(after_time);
  const time = data.getTime(); // 时间戳

  // 获取当前时间戳
  const nowTime = new Date().getTime();

  // 获取年月日时分秒
  const YYYY = data.getFullYear();
  const MM = data.getMonth() + 1;
  const DD = data.getDate();
  const hh = data.getHours();
  const mm = data.getMinutes();
  const ss = data.getSeconds();
  const ms = data.getMilliseconds();

  // 计算时间差异（分钟）
  const diff = nowTime - time;

  return {
    YYYY: numberToAll(YYYY),
    MM: numberToAll(MM),
    DD: numberToAll(DD),
    hh: numberToAll(hh),
    mm: numberToAll(mm),
    ss: numberToAll(ss),
    ms: numberToAll(ms),
    diff,
  };
};

// 根据时间字符转换中文时间字符
export const timeDateToYMDhms = (time) => {
  if (!time) {
    return;
  }
  const [YMD, hms] = time.split(" ");
  const [YYYY, MM, DD] = YMD.split("-");
  return `${YYYY}年${MM}月${DD}日 ${hms}`;
};

// 根据时间格式 转换数据
export const formatArrayToData = (arr = []) => {
  const format_YMD = ["YYYY", "MM", "DD"];
  const YMD = arr
    .filter((item) => format_YMD.includes(item.type))
    .map((item) => item.value)
    .join("-");

  const format_hms = ["hh", "mm", "ss"];
  const hms = arr
    .filter((item) => format_hms.includes(item.type))
    .map((item) => item.value)
    .join(":");

  let result = "";
  if (YMD) {
    result += YMD;
  }
  if (hms) {
    result += hms;
  }

  return result;
};

// 获取指定时间
export const timeSpToDate = (time, afterType) => {
  let date = new Date(iosTimeFormat(time));
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  let result = `${year}-${numberToAll(month)}-${numberToAll(day)}`;

  const nopass = [undefined, null, ""];
  if (!nopass.includes(afterType)) {
    const endAfters = ["00:00:00", "23:59:59"];
    const resAfter = endAfters[afterType];
    result += " " + resAfter;
  }
  return result;
};

// 根据时间戳获取 time时间
export const timeStampToTime = (time, config) => {
  const { format = "YYYY-MM-DD hh:mm:ss" } = config || {};

  const { YYYY, MM, DD, hh, mm, ss } = timeRules(time || new Date());

  const params = {
    YYYY,
    MM,
    DD,
    hh,
    mm,
    ss,
  };

  let timeResult = "";

  const [YMD = "", hms = ""] = format.split(" ");

  if (YMD) {
    const formats = YMD.split("-");
    const result = formats.map((item) => numberToAll(params[item]));
    timeResult += result.join("-");
  }

  if (hms) {
    const formats = hms.split(":");
    const result = formats.map((item) => numberToAll(params[item]));
    timeResult += (YMD ? " " : "") + result.join(":");
  }
  return timeResult;
};

// ******************************************************************
// 时间处理 END
// ******************************************************************

// ******************************************************************
// 其他 START
// ******************************************************************

// 判断是否移动端
export const isNavigatorClient = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

// 调用pinia模块
export const usePiniaModule = function (useName) {
  if (useName) {
    return piniaStore[useName]();
  } else {
    const obj = {};
    for (let i in piniaStore) {
      obj[i] = piniaStore[i]();
    }
    return obj;
  }
};

//获取屏幕缩放比例
export const getWindowRatio = () => {
  var ratio = 0;
  var screen = window.screen;
  var ua = navigator.userAgent.toLowerCase();

  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf("msie")) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (
    window.outerWidth !== undefined &&
    window.innerWidth !== undefined
  ) {
    ratio = window.outerWidth / window.innerWidth;
  }

  return ratio;
};

// 默认TDK内容数据
const TDKDefaultData = () => ({
  content:
    "深圳市云效科技有限公司-专注为客户提供高性价比优质的软件开发服务，珍惜每一位客户以建立长期的合作关系，实现共赢，Web3开发-Web3外包-小程序设计-小程序开发-网页设计-网页开发",
  title: "云效科技-软件外包-网站定制-APP开发-小程序定制-公众号定制",
  keywords:
    "云效,深圳市云效科技有限公司,小程序开发,公众号开发,软件开发,HTML5开发,微信开发,网站建设,app开发,网页开发,后台管理,可视化,IT,管理系统,java开发,前端开发",
});
// 本地动态赋值 html TDK标签赋值
export const renderTDKHtml = (opts) => {
  const { content, title, keywords } = opts || TDKDefaultData();
  const head = document.getElementsByTagName("head");
  const meta = document.createElement("meta");
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute("content", keywords || title || "");

  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", content || "");

  head[0].appendChild(meta);
  document.title = title || "";
};

// 获取时间戳16进制编码
export const getStamp16BinaryCode = function () {
  const { YYYY, MM, DD, hh, mm, ss, ms } = timeRules(Date.now());
  const codes = [YYYY, MM, DD, hh, mm, ss, ms];
  const code = codes.join("");
  return code;
};

// ******************************************************************
// 其他 END
// ******************************************************************

// ******************************************************************
// 文件 START
// ******************************************************************

// ******************************************************************
// 文件 END
// ******************************************************************
