<template>
  <div class="ml-select">
    <el-select
      :popper-class="popperClass"
      v-model="value"
      :placeholder="usePlaceholder"
      :filterable="filterable"
      :clearable="clearable"
      :disabled="disabled"
      :size="size"
      :multiple="multiple"
      :collapse-tags-tooltip="collapseTagsTooltip"
      :collapse-tags="collapseTags"
      @change="onChange"
    >
      <el-option
        :label="item.label"
        :value="item.value"
        v-for="(item, index) in selectOptions"
        :key="index"
      >
        <slot :option="item">{{ item.label }}</slot>
      </el-option>
    </el-select>
  </div>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
const emits = defineEmits(["update:modelValue", "change"]);

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean, Array],
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "请选择",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  selectProps: {
    type: Object,
    default: () => ({
      labelKey: "label",
      valueKey: "value",
    }),
  },
  filterable: {
    type: Boolean,
    default: true,
  },
  // 唯一class
  popperClass: {
    type: String,
    default: "select-popper",
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  collapseTagsTooltip: {
    type: Boolean,
    default: true,
  },
  collapseTags: {
    type: Boolean,
    default: false,
  },
  // 禁用状态
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

// 选择器值
let value = ref(props.modelValue);

// options
const selectOptions = computed(() => {
  let selectProps = props.selectProps;

  return props.options.map((item) => {
    return {
      ...item,
      label: item[selectProps.labelKey],
      value: item[selectProps.valueKey],
    };
  });
});

// 赋值
watch(
  () => props.modelValue,
  (nVal) => {
    value.value = nVal;
  }
);

// 回显
watch(
  () => value.value,
  (nVal) => {
    emits("update:modelValue", nVal);
  }
);

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const val = value.value;

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 监听选择器变化
const onChange = (res) => {
  emits("change", res);
};
</script>

<style lang="scss">
.ml-select {
  .el-select {
    // 输入框
    .el-input {
      &.is-disabled {
        cursor: text;
        .el-input__wrapper {
          // background-color: #8989890f !important;
          background-color: #ffffff1a !important;
        }
        .el-input__inner {
          // -webkit-text-fill-color: black;
          -webkit-text-fill-color: var(--el-text-color-regular);
          cursor: text;
          // height: 34px !important;
        }
      }
    }
  }
}
</style>
