<template>
  <div id="body">
    <!-- <el-scrollbar height="100%" ref="scrollbarRef" @scroll="onScrollBar"> -->
    <router-view />
    <!-- </el-scrollbar> -->
  </div>
</template>

<script setup>
import {
  watch,
  computed,
  ref,
  getCurrentInstance,
  onMounted,
  onUnmounted,
} from "vue";
import piniaStore from "@/piniaStore/index";

const { proxy } = getCurrentInstance();

const mainStore = piniaStore.mainStore();
const dataStore = piniaStore.dataStore();

// ref
const scrollbarRef = ref();

// 初始拦截
const isInitReturn = ref(true);

// 当前路由信息
const currRouteInfo = computed(() => proxy.$route);
// 路由路径
const routePath = computed(() => currRouteInfo.value.path);
watch(
  () => routePath.value,
  (nVal, oVal) => {
    // 重置 scrollTop
    window.scrollTo({ top: 0 });

    // 重置
    isInitReturn.value = true;

    // 需要记录scrollTop的页面
    const needRecordPath = ["/", "/case", "/about", "/serve"];

    // 监听当前的路径进行设置
    if (needRecordPath.includes(nVal)) {
      // 当前 记录路由的scrollTop值
      const currRouteRecordScrollTop =
        mainStore.recordRouteScrollbarTop[nVal] || 0;
      // console.log(mainStore.recordRouteScrollbarTop);
      // console.log(currRouteRecordScrollTop);

      setTimeout(() => {
        window.scrollTo({ top: currRouteRecordScrollTop });
      });
    }

    setTimeout(() => {
      // 解除
      isInitReturn.value = false;
    }, 500);
  },
  {
    deep: true,
  }
);

// 监听 当前scrollTop
const onScrollBar = (e) => {
  if (isInitReturn.value) {
    return;
  }

  const scrollTop = window.scrollY;
  const path = routePath.value;
  // 需要记录scrollTop的页面
  const needRecordPath = ["/", "/case", "/about", "/serve"];
  // 监听 之前的路径进行赋值
  if (needRecordPath.includes(path)) {
    // console.log(scrollTop);
    // console.log(path);
    // 记录
    mainStore.recordRouteScrollbarTop[path] = scrollTop;
  }

  /* 记录鼠标操作 */
  const { innerWidth, innerHeight } = window;
  //鼠标滚动
  const stamp = Date.now();
  const params = {
    scrollTop,
    showWidth: innerWidth,
    showHeight: innerHeight,
  };

  dataStore.globalStoreMouseOperationRecord.push({
    ...params,
    stamp,
    type: "scroll",
  });

  // 覆盖window scroll
  // console.log(e)
};

// 初始监听window
const initWindowOperate = () => {
  // 监听窗口滚动
  window.onscroll = onScrollBar;
};

onMounted(() => {

  initWindowOperate();

  setTimeout(() => {
    // 关闭
    isInitReturn.value = false;
  });
});

onUnmounted(() => {
  // 关闭
  // inobounce.disable();
});
</script>

<style lang="scss">
@import "@/assets/style/root.scss";
@import "@/assets/style/global.scss";
// @import "./assets/font-wrap/index.css";
* {
  padding: 0;
  margin: 0;
  font-family: "AlimamaDaoLiTi";
}

body {
  position: relative;
}

#body {
  width: 100%;
  // background-color: red;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS<11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容iOS>= 11.2 */
}

// 鼠标操作模拟圆形标点
.bar-round {
  width: 10px;
  height: 10px;
  background-color: black;
  box-shadow: 0px 0px 10px 2px #eb0909;
  position: fixed;
  opacity: 0;
  border-radius: 50px;
  top: calc(50vh - 5px);
  left: calc(50vw - 5px);
  z-index: 9999;

  &.move-bar {
    opacity: 1;
  }

  &.is-click {
    background-color: red;
  }
}
</style>

<style></style>
