import NProgress from "nprogress";

// 请求拦截
/* 
	@param {object} custom
	isAuth 是否是需要授权验证
	customUrl 自定义请求接口
 */

import { getItem, paramToStr, isEmpty } from "../module";

import { STORE_NAME_AUTH_TOKEN } from "@/utils/storeAgeTables";

export const request = (axios) => {
  axios.interceptors.request.use(
    (option) => {
      // console.log('resolve')
      // console.log(option)
      // 进度条状态
      NProgress.done();

      // 授权
      let auth = getItem(STORE_NAME_AUTH_TOKEN) || "";

      // 携带配置
      let { header = {} } = option || {};

      // 自定义 设置
      let {
        isAuth,
        customApiUrl = "",
        query = null,
        showNProgress,
      } = option.custom || {};

      // 默认为true
      isAuth = isEmpty(isAuth) || isAuth;
      showNProgress = isEmpty(showNProgress) || showNProgress;

      let opt = {
        ...option,
      };

      if (showNProgress) {
        NProgress.inc();
      }

      if (query) {
        const urljson = paramToStr(query);
        // 请求配置
        opt = {
          ...opt,
          url: `${option.url}?${urljson}`,
        };
      }
      // console.log(opt)
      // 需要授权验证
      if (isAuth) {
        opt.headers = {
          ...opt.headers,
        };
      }

      // 自定义请求接口
      if (customApiUrl) {
        const { originUrl = "" } = opt;
        opt.baseURL = customApiUrl + originUrl;
        opt.url = "";
        // console.log(customApiUrl);
        // console.log(opt);
      }

      // console.log(opt)
      return opt;
    },
    (option) => {
      // console.log('reject')
      // console.log(option)

      NProgress.done();
      return Promise.reject(option);
    }
  );
};
