<template>
  <div class="contact-form-container mobile-wrap">
    <div class="wrap-title">
      <div class="sub-title fontMedium">
        提交您的软件定制需求，我们将免费为您规划项目需求并输出方案！
      </div>
    </div>
    <div class="form">
      <el-form ref="formRef" :rules="clientFormRules" :model="clientForm">
        <div class="input-top">
          <el-form-item>
            <ml-input
              placeholder="联系电话"
              v-model="clientForm.contactWay"
            ></ml-input>
          </el-form-item>
          <div></div>
          <el-form-item>
            <ml-input
              placeholder="称呼姓名"
              v-model="clientForm.named"
            ></ml-input>
          </el-form-item>
        </div>

        <el-form-item>
          <ml-input
            type="textarea"
            placeholder="留下您的需求，获取免费解决方案或报价信息（选填）"
            v-model="clientForm.demandDescribe"
          ></ml-input>
        </el-form-item>
      </el-form>

      <div class="phone-box">
        <div class="phone">
          <div class="image">
            <img :src="phoneImage" alt="" />
          </div>
          <div class="fontRegular">177-2247-6866</div>
        </div>
        <div class="notes fontRegular">
          请认真填写需求信息，我们会在10分钟内与您取得联系。
        </div>
      </div>
      <div class="btn">
        <ml-button id="form-submit" useLoading @submit="formSubmit">
          <span class="fontRegular">点击提交</span>
        </ml-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, ref, getCurrentInstance } from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

const { formRef, clientForm, clientFormRules, formSubmit } =
  datajs.apply(proxy);

// 电话
const phoneImage = require("@/assets/images/home/phone-icon.png");

// *******************************************************************************************
// 表单操作 START
// *******************************************************************************************

// *******************************************************************************************
// 表单操作 END
// *******************************************************************************************
</script>

<style lang="scss">
.contact-form-container {
  &.mobile-wrap {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .wrap-title {
      .sub-title {
        color: white;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .form {
      width: 100%;
      .input-top {
        width: 100%;
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 1fr 17px 1fr;
        .el-form-item {
          width: 100% !important;
          .el-form-item__content {
            width: 100% !important;
          }
          .ml-input {
            width: 100% !important;
          }

          .el-input__wrapper {
            font-size: 13px;
            width: 100% !important;
            height: 44px;
            border: none;
            border-radius: 5px;
            padding: 0 15px;
            box-sizing: border-box;
            outline: none;
          }
        }
      }

      .ml-input {
        &.textarea {
          width: 100%;
          textarea {
            width: 100%;
            border-radius: 5px;
            border: none;
            outline: none;
            resize: none;
            padding: 15px;
            box-sizing: border-box;
            font-size: 13px;
          }
        }
      }

      .phone-box {
        display: flex;
        color: white;
        margin-top: 10px;
        flex-direction: column;
        .phone {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .image {
            width: 13px;
            height: 13px;
            display: flex;
            margin-right: 6px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          div {
            font-size: 13px;
          }
        }

        .notes {
          font-size: 12px;
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        button {
          width: 160px;
          height: 48px;
          background: #ffffff;
          border: none;
          color: #0a41ff;
          font-size: 14px;
          font-weight: bold;
          border-radius: 5px;
          transition: background 300ms;
          &:active {
            background-color: #ffffffe6;
          }
        }
      }

      input::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #9b9eb3;
        font-size: 13px;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #9b9eb3;
        font-size: 13px;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #9b9eb3;
        font-size: 13px;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #9b9eb3;
        font-size: 13px;
      }

      textarea::-webkit-input-placeholder {
        /* WebKit browsers，webkit内核浏览器 */
        color: #9b9eb3;
        font-size: 13px;
      }
      textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #9b9eb3;
        font-size: 13px;
      }
      textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #9b9eb3;
        font-size: 13px;
      }
      textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #9b9eb3;
        font-size: 13px;
      }
    }
  }
}
</style>
