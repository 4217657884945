export default [
  // 获取新闻分类
  {
    name: "getNewsCategoryList",
    url: "/v1/open/news/category/getNewsCategoryList",
    method: "get",
  },
  // 获取新闻分页列表
  {
    name: "getNewsPageListReporting",
    url: "/v1/open/news/getNewsPageListReporting",
    method: "get",
  },
  // 获取相邻新闻
  {
    name: "getAdjacentNews",
    url: "/v1/open/news/getAdjacentNews",
    method: "get",
  },
  // 获取新闻详情
  {
    name: "getNews",
    url: "/v1/open/news/getNews",
    method: "get",
  },
  // 获取最新新闻
  {
    name: "getNewNewsListReporting",
    url: "/v1/open/news/getNewNewsListReporting",
    method: "get",
  },
];
