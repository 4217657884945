<template>
  <!-- 折叠面板 -->
  <div
    class="ml-collapse"
    :class="{
      'is-border-up': useShowUpBorder,
      'is-border-down': useShowDownBorder,
    }"
  >
    <div
      class="ml-collapse__title"
      :class="{
        sticky: titleSticky,
      }"
    >
      <slot name="title">
        <div class="ml-collapse__title-text">
          <slot name="title-left"> {{ title }}</slot>
        </div>

        <div class="ml-collapse__title-handle" v-if="isUseExpand">
          <slot name="title-right">
            <ml-btn
              v-if="isUseExpand"
              :disabled="useDisabled"
              type="default"
              @handleClick="handleExpand"
              :bypassDelay="0"
            >
              {{ expandBtnText }}
            </ml-btn>
          </slot>
        </div>
      </slot>
    </div>

    <slot name="title-down"> </slot>

    <div class="ml-collapse__body">
      <div
        class="ml-collapse__body-expand"
        :class="{
          'is-expand': collapseState.isExpand,
          'is-animate': isUseAnimate,
          'is-all-expand': !isUseExpand,
        }"
        :style="showBodyStyle"
      >
        <div :class="`slot-content collapse-content-${collapseKey}`">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  reactive,
  getCurrentInstance,
  nextTick,
  watch,
} from "vue";

const emits = defineEmits(["onExpand", "onRetract"]);

const props = defineProps({
  // 模式 normal||active
  mode: {
    type: String,
    default: "normal",
  },
  // 显示线条
  showBorder: {
    type: Boolean,
    default: true,
  },
  // 显示上线条
  showUpBorder: {
    type: Boolean,
    default: true,
  },
  // 显示下线条
  showDownBorder: {
    type: Boolean,
    default: false,
  },
  // 是否默认展开
  defaultExpand: {
    type: Boolean,
    default: true,
  },
  // 标题
  title: {
    type: [String, Number],
    default: "",
  },
  collapseKey: {
    type: [String, Number],
    default: 0,
  },
  // 是否禁用
  disabled: {
    type: Boolean,
    default: false,
  },
  // 是否使用折叠功能
  isUseExpand: {
    type: Boolean,
    default: true,
  },
  // 标题吸顶
  titleSticky: {
    type: Boolean,
    default: true,
  },
});

const { proxy } = getCurrentInstance();

/* 折叠数据 */
// 默认
const defaultCollapseState = () => ({
  expandHeight: 0, //展开高度
  isExpand: props.defaultExpand,
  useAnimate: true,
});
const collapseState = reactive(defaultCollapseState());

// 是否禁用
const useDisabled = computed(() => props.disabled);

// 常规 body的样式
const showBodyStyle = computed(() => {
  const { expandHeight, isExpand, useAnimate } = collapseState;

  const height = `${useAnimate ? expandHeight + "px" : "auto"}`;

  return isExpand
    ? {
        height,
      }
    : {
        height: "0px",
      };
});

// 是否使用展开动画
const isUseAnimate = computed(() => collapseState.useAnimate);

// 展开按钮文案
const expandBtnText = computed(() => {
  return collapseState.isExpand ? "收起" : "展开";
});

// 显示边线
const useShowBorder = computed(() => props.showBorder);
// 显示上边线
const useShowUpBorder = computed(
  () => useShowBorder.value && props.showUpBorder
);
// 显示下边线
const useShowDownBorder = computed(
  () => useShowBorder.value && props.showDownBorder
);

/* 操作 */

// 无动画操作
const notAnimateExpand = (res) => {
  collapseState.useAnimate = false;
  controlExpand(res);
};

// 常规操作
const handleExpand = (res) => {
  // 操作重置
  collapseState.useAnimate = true;
  getCollapseHeight();

  nextTick(() => {
    controlExpand(res);
  });
};
// 控制折叠
const controlExpand = (res) => {
  console.log(res);
  getCollapseHeight();
  if (useDisabled.value) {
    return;
  }

  if (!proxy.$isEmpty(res)) {
    collapseState.isExpand = res;
  } else {
    const { isExpand } = collapseState;
    collapseState.isExpand = !isExpand;
  }

  if (collapseState.isExpand) {
    emits("onExpand");
  } else {
    emits("onRetract");
  }
};

/* 查询 */
// 查询body的高度
const getCollapseHeight = () => {
  const bodyElement =
    document.getElementsByClassName(
      `collapse-content-${props.collapseKey}`
    )[0] || {};
  const height = bodyElement.clientHeight || 0;
  collapseState.expandHeight = height;
  // console.log(
  //   document.getElementsByClassName(`collapse-content-${props.collapseKey}`)
  // );
  // console.log(bodyElement);
  // console.log(height);
};

// 初始
const init = (opt) => {
  // 默认使用动画
  const { useAnimate = true } = opt || {};

  getCollapseHeight();

  // 不使用 展开
  // if (!props.isUseExpand) {
  // 使用动画
  if (useAnimate) {
    handleExpand(collapseState.isExpand || props.defaultExpand);
  } else {
    // 不使用动画
    notAnimateExpand(collapseState.isExpand || props.defaultExpand);
  }
  // }
};

onMounted(() => {
  init();
});

defineExpose({
  init,
  handleExpand,
  notAnimateExpand,
});
</script>

<style lang="scss" scoped>
.ml-collapse {
  &.is-border-up {
    border-top: 1px solid var(--c-border-color);
  }
  &.is-border-down {
    border-bottom: 1px solid var(--c-border-color);
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    &-text {
      font-size: 20px;
      font-weight: 700;
    }
    &-handle {
    }
  }
  &__body {
    &-expand {
      overflow: hidden;
      &.is-animate {
        transition: 300ms ease-in-out;
      }
      &.is-all-expand {
        height: 100% !important;
      }
      &.is-expand {
      }

      .slot-content {
        height: auto;
      }
    }
  }

  // 吸顶
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2001;
  }
}
</style>
