<template>
  <div
    class="list-card-container"
    :class="{ 'is-pointer': isPointer }"
    @click="onCardClick"
  >
    <div class="card-item">
      <div class="item">
        <div class="image">
          <img :src="src" alt="" />
        </div>

        <!-- 角标 -->
        <div
          v-if="showFreeTrialTag"
          class="subscript"
          :style="{ backgroundColor }"
        >
          在线免费试用
        </div>

        <div
          v-if="showDownName"
          class="title fontBold"
          :class="{ 'is-expand': isExpandName }"
          :style="{ backgroundColor }"
        >
          {{ name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance } from "vue";

const props = defineProps({
  src: {
    type: [String, Blob],
    default: "",
  },
  backgroundColor: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  routeQuery: {
    type: Object,
    default: () => ({}),
  },
  // 展开名称
  isExpandName: {
    type: Boolean,
    default: false,
  },
  // 显示名称
  showDownName: {
    type: Boolean,
    default: true,
  },
  isPointer: {
    type: Boolean,
    default: true,
  },
  // 索引位置
  keyIndex: {
    type: [String, Number],
    default: "",
  },
  // 是否展示免费试用按钮
  showFreeTrialTag: {
    type: Boolean,
    default: false,
  },
});

const { proxy } = getCurrentInstance();

const { mainStore, dataStore } = proxy.$usePiniaModule();

// 监听卡片点击
const onCardClick = (e) => {
  /* 鼠标操作 */
  // 记录
  dataStore.setGlobalClickRecord(
    function (params) {
      const { value } = params;
      document.getElementsByClassName("list-card-container")[value].click();
    },
    {
      value: props.keyIndex,
    }
  );

  jumpCase();
};
// 跳转案例
const jumpCase = () => {
  const { routeQuery } = props;

  proxy.$router.push({
    path: "/listCase",
    query: { name: routeQuery.name, stemp: Date.now() },
  });

  setTimeout(() => {
    window.scrollTo({ top: 0 });
  });
};
</script>

<style lang="scss" scoped>
.list-card-container {
  &.addPadding {
    padding: 37px;
  }
  &.noPadding {
    padding: 0;
  }
  &.is-pointer {
    cursor: pointer;
  }
  .card-item {
    // padding: 37px;

    .item {
      font-size: 14px;
      font-weight: bold;
      position: relative;

      //角标
      .subscript {
        position: absolute;
        top: 10px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 13px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }

      &:hover {
        .title {
          width: 130px;
          color: white;
          transition: width 200ms, color 600ms;
          border-radius: 20px;
        }
      }

      .image {
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        min-width: 38px;
        width: 38px;
        height: 38px;
        line-height: 38px;
        border-radius: 38px;
        color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        transition: width 600ms, color 200ms;
        overflow: hidden;
        margin-top: 30px;

        &.is-expand {
          width: 130px;
          color: white;
        }
      }
    }
  }
}
</style>
