import mlDialog from "./ml-dialog";
import mlForm from "./ml-form.vue";
import mlSelect from "./ml-select.vue";
import mlButton from "./ml-button.vue";
import mlImage from "./ml-image.vue";
import mlInput from "./ml-input.vue";
import mlDateTime from "./ml-date-time.vue";
import mlCollapsePanel from "./ml-collapse-panel.vue";
import mlDrawer from "./ml-drawer.vue";

const comps = {
  mlDialog,
  mlForm,
  mlSelect,
  mlButton,
  mlImage,
  mlInput,
  mlDateTime,
  mlCollapsePanel,
  mlDrawer,
};

export default {
  install(Vue) {
    for (let i in comps) {
      Vue.component(i, comps[i]);
    }
  },
};
