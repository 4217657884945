// 常规数据处理

import { defineStore } from "pinia";

import { updateParams, removeDecontextAttr } from "@/utils/module";

// 当前本地状态默认数据
const currStateDefaults = () => ({
  /* 全局存储鼠标操作 */
  // 操作记录
  globalStoreMouseOperationRecord: [],
  // 连续添加次数
  globalMouseRecordCount: 1,
});

export default defineStore({
  id: "data",
  state: () => currStateDefaults(),
  actions: {
    // 重置本地状态
    resetLocalState() {
      updateParams(this, currStateDefaults());
    },

    /* 全局存储鼠标操作 */
    // 设置鼠标点击
    setGlobalClickRecord(callback, params = {}) {
      const stamp = Date.now();
      this.globalStoreMouseOperationRecord.push({
        stamp,
        type: "mouseup",
        callback: callback.toString(),
        params,
      });
      // console.log(JSON.stringify(this.globalStoreMouseOperationRecord));
      // console.log(this.globalStoreMouseOperationRecord);
    },
  },
  getters: {
    /* 全局存储鼠标操作 */
    // 唯一标识
    getGlobalStoreMouseID: (state) => {
      return Date.now();
    },
    // 操作数据
    getGlobalStoreMouseRecord(state) {
      const result = this.globalStoreMouseOperationRecord.reduce(
        (acc, curr) => {
          if (acc.length) {
            const lastItem = acc[acc.length - 1];

            // 得到延迟
            const delay = curr.stamp - lastItem.stamp;
            curr.delay = delay;

            acc.push(curr);
          } else {
            curr.delay = 0;
            acc.push(curr);
          }

          return acc;
        },
        []
      );

      return removeDecontextAttr(result).map((d) => {
        delete d["stamp"];
        return d;
      });
    },
  },
});
