import contactForm from "./contact-form/index.vue";

/* 示例盒子 */
import listCard from './list-card/index.vue'

const components = {
  contactForm,
  listCard
};

export default {
  install(Vue) {
    for (let i in components) {
      Vue.component(i, components[i]);
    }
  },
};
