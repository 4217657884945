import { defineStore } from "pinia";
import { timeSpToDate, timeStampToTime } from "@/utils/module.js";

// 当前本地状态默认数据
const currStateDefaults = () => ({
  // http axios的cancel source
  httpSource: null,

  // 自定义layout标题
  customLayOutTitle: "",

  // 当前window 的 innerWidth
  windowInnerWidth: 0,
  // 当前window 的 outerWidth
  windowOuterWidth: 0,

  // 记录页面的跳转之前的scrollTop
  recordRouteScrollbarTop: {},

  // 控制顶部header的显示
  showHeaderNavbar: true,
  // 控制底部foor的显示
  showDownFooter: true,
});

// 随机生成字母
const randCode = (length = 0, mode = "toLowerCase") => {
  let randStr = "";
  for (let i = 0; i < length; i++) {
    const random = Math.random() * 26 + "a".charCodeAt(0);
    randStr += String.fromCharCode(Math.floor(random));
  }

  return randStr[mode]();
};

export default defineStore({
  id: "main",
  state: () => currStateDefaults(),
  actions: {
    // 获取提交分组编号
    getSubmitGroupNo: () => {
      const YMD = timeSpToDate(Date.now()).split("-").join("");
      const HMS = timeStampToTime(Date.now(), {
        format: "HH:mm",
      })
        .split(":")
        .join("");
      const randCodeText = randCode(6, "toUpperCase");
      return `${YMD + HMS}-${randCodeText}`;
    },
  },
  getters: {
    getHttps: (state) => state.https,
    // 计算当前比例
  },
});
