// 服务器配置

const config = {
  // 线上
  online() {
    // 请求IP 前置
    let serveIP = "https://cloudeffective.cn";
    // 端口
    let port = "";
    // 接口名 前置
    let prefix = "system";
    // 本地文件预览
    let previewFilePort = "";
    let previewFilePerfix = `/${prefix}/v1/open/file/preview/`;

    return {
      serveIP,
      prefix,
      //接口
      serveUrl: serveIP + `${port}/`,
      // 预览图片
      previewFileUrl: serveIP + `${previewFilePerfix}`,
    };
  },
  // 本地
  local() {
    // 请求IP 前置
    let serveIP = "http://192.168.1.10";
    // 端口
    let port = ":5001";
    // 接口名 前置
    let prefix = "system";
    // 本地文件预览
    let previewFilePort = "";
    let previewFilePerfix = `${previewFilePort}/oss/v1/open/file/preview/`;

    return {
      serveIP,
      prefix,
      //接口
      serveUrl: serveIP + `${port}/`,
      // 预览图片
      previewFileUrl: serveIP + `${previewFilePerfix}`,
    };
  },
};

// 使用配置
const useConfig = config["online"]();

// const { operateFileUrl, operateReportFileUrl, updateFileUrl } =
//   config["online"]();
// useConfig.updateFileUrl = updateFileUrl;

// useConfig.operateFileUrl = operateFileUrl;
// useConfig.operateReportFileUrl = operateReportFileUrl;

module.exports = {
  ...useConfig,
};
