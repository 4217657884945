import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createPinia } from "pinia";

const pinia = createPinia();

import "@/assets/font-wrap/index.css";

// element-plus 样式
import "element-plus/dist/index.css";
import elementPlus from "element-plus";

// 国际化
import zhCn from "element-plus/es/locale/lang/zh-cn";

// element-plus icon 图标
import * as Icons from "@element-plus/icons-vue";

// 组件
// 自定义组件
import components from "./components/index";
// 自定义组件
import elCustom from "./el-custom/index";

// 加载进度条
import "nprogress/nprogress.css";
import NProgress from "nprogress";

// 配置
NProgress.configure({
  showSpinner: false, //关闭进度环
});

const app = createApp(App);
app
  .use(elementPlus, {
    locale: zhCn,
  })
  .use(store)
  .use(router)
  .use(components)
  .use(elCustom)
  .use(pinia)
  .mount("#app");

// 全局自定义插件方法设置
import * as globalModule from "@/utils/module";
import * as apiConfig from "@/utils/apiConfig";
import * as mainConfig from "@/utils/main";
const globalProperties = {
  ...globalModule,
  ...apiConfig,
  ...mainConfig
};
for (let i in globalProperties) {
  app.config.globalProperties["$" + i] = globalProperties[i];
}

// 遍历所有图标进行注册
for (const name in Icons) {
  app.component(name, Icons[name]);
}

const { initApi } = require("@/utils/http/index");
initApi(store);

// 路由卫士
router.beforeEach((to, from, next) => {
  NProgress.inc();
  next();
});
router.afterEach((to) => {
  NProgress.done();
});
