<template>
  <div class="ml-date-time">
    <el-date-picker
      ref="elDatePickerRef"
      v-model="dateTime"
      :type="type"
      :placeholder="usePlaceholder"
      :start-placeholder="startPlaceholder"
      :end-placeholder="endPlaceholder"
      @change="changeDateTime"
      @focus="onFocus"
      :format="useFormat"
      :disabled="disabled"
      :default-value="defaultValue"
      :popper-class="popperClass"
    />
  </div>
</template>

<script setup>
import {
  ref,
  getCurrentInstance,
  watch,
  computed,
  onMounted,
  nextTick,
} from "vue";
const emits = defineEmits([
  "update:startTimeValue",
  "update:endTimeValue",
  "update:modelValue",
  "change",
]);

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  startTimeValue: {
    type: String,
    default: "",
  },
  endTimeValue: {
    type: String,
    default: "",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "选择时间",
  },
  // 缺省占位符
  emptyPlaceholder: {
    type: String,
    default: "暂无",
  },
  startPlaceholder: {
    type: String,
    default: "开始时间",
  },
  endPlaceholder: {
    type: String,
    default: "结束时间",
  },
  type: {
    type: String,
    default: "daterange",
  },
  size: {
    type: String,
    default: "",
  },
  // 显示在输入框中的格式
  format: {
    type: String,
    default: "YYYY-MM-DD",
  },
  // 是否在
  isDateTimeAfter: {
    type: Boolean,
    default: true,
  },
  // 默认时间
  defaultValue: {
    type: Date,
    default: new Date(),
  },
  // 默认选择类型 year||month||date
  defaultChangeType: {
    type: String,
    default: "",
  },
  popperClass: {
    type: String,
    default: "data-picker",
  },
});

const { proxy } = getCurrentInstance();

// ref
const elDatePickerRef = ref();

// 时间
const dateTime = ref(props.type == "daterange" ? [] : "");

watch([() => props.startTimeValue, () => props.endTimeValue], (nVals) => {
  const [start = "", end = ""] = nVals;
  dateTime.value = [start, end];
});

watch(
  () => props.modelValue,
  (nVal) => {
    dateTime.value = nVal;
  },
  {
    deep: true,
    immediate: true,
  }
);

// 占位符显示
const usePlaceholder = computed(() => {
  const { placeholder, disabled, emptyPlaceholder } = props;
  const val =
    typeof props.modelValue == "string"
      ? props.modelValue
      : props.modelValue.filter((d) => !!d).join(",");

  // 显示缺省占位符
  const showEmptyPlaceholder = disabled && proxy.$isEmpty(val);
  return showEmptyPlaceholder ? emptyPlaceholder : placeholder;
});

// 选择时间
const changeDateTime = (nVal) => {
  const { isDateTimeAfter, type } = props;
  if (type == "daterange") {
    const [startTime = "", endTime = ""] = (nVal || []).map((item, index) => {
      const stNum = new Date(item).getTime();
      return proxy.$timeSpToDate(stNum, isDateTimeAfter ? index : "");
    });

    emits("update:startTimeValue", startTime);
    emits("update:endTimeValue", endTime);
    emits("change", [startTime, endTime]);
  } else {
    emits(
      "update:modelValue",
      proxy.$timeSpToDate(nVal, isDateTimeAfter ? 1 : "")
    );
  }
};

const defaultChangeTypes = ["year", "month", "date"];
// 监听 焦点
const onFocus = (e) => {
  if (props.defaultChangeType) {
    nextTick(() => {
      const typeIndex = defaultChangeTypes.findIndex(
        (d) => d == props.defaultChangeType
      );
      document
        .querySelector("." + props.popperClass)
        .querySelectorAll(".el-date-picker__header-label")
        [typeIndex].click();
    });
  }
};

// 使用的格式
const useFormat = computed(() => {
  return props.format;
});
</script>

<style lang="scss">
.inline-block {
  .ml-date-time {
    width: 100%;
    .el-input {
      width: 100%;
    }

    @mixin useDisabled {
      cursor: text;
      background-color: #ffffff1a !important;
      -webkit-text-fill-color: var(--el-text-color-regular);
      cursor: text;
    }

    .el-range-editor.is-disabled {
      @include useDisabled;
      input {
        @include useDisabled;
      }
    }
  }
}
</style>
