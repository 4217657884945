import { storeResponse } from "@/utils/module";
import { updateFileUrl } from "@/utils/apiConfig";

export default {
  actions: {
    // 修改密码
    async fetchUpdatePasswordPost({ getters }, params) {
      const { updatePasswordPost } = getters.getHttps;

      const result = await updatePasswordPost(params);

      return storeResponse(result);
    },

    // 添加客户需求
    async fetchAddClientDemand({ getters }, params) {
      const { addClientDemand } = getters.getHttps;

      const result = await addClientDemand(params);

      return storeResponse(result);
    },

    // 添加客户需求词条
    async fetchAddClientDemandEntry({ getters }, params) {
      const { addClientDemandEntry } = getters.getHttps;

      const result = await addClientDemandEntry(params,{
        custom: {
          showNProgress: false,
        },
      });

      return storeResponse(result);
    },

    // 添加访问量统计
    async fetchAddStatisticsVisits({ getters }, params) {
      const { addStatisticsVisits } = getters.getHttps;
      await addStatisticsVisits(params, {
        custom: {
          showTips: false,
        },
      });
      return;
    },

    // 上传图片
    async fetchUploadImage({ getters }, params) {
      const { uploadImage } = getters.getHttps;
      const result = await uploadImage(params, {
        custom: {
          customApiUrl: updateFileUrl,
          isFile: true,
        },
      });

      return storeResponse(result);
    },

    // 添加路径轨迹
    async fetchPtsSux({ getters }, params) {
      const { pts_sux } = getters.getHttps;

      const result = await pts_sux(params, {
        custom: {
          showTips: false,
          showNProgress: false,
        },
      });

      return result;
    },

    // 获取路径轨迹数据集合
    async fetchGetPathTraceDataList({ getters }, params) {
      const { getPathTraceDataList } = getters.getHttps;

      const result = await getPathTraceDataList(params);

      return storeResponse(result);
    },
  },
};
