export default [
  // 修改密码
  {
    name: "updatePasswordPut",
    url: "/systemUser/updatePassword",
    method: "put",
  },

  // 添加客户需求
  {
    name: "addClientDemand",
    url: "/v1/open/client/demand/addClientDemand",
    method: "post",
  },

  // 添加客户需求词条
  {
    name: "addClientDemandEntry",
    url: "/v1/open/client/demand/entry/addClientDemandEntry",
    method: "post",
  },

  // 添加访问量统计
  {
    name: "addStatisticsVisits",
    url: "/v1/vis_1",
    method: "post",
  },

  // 文件上传
  { name: "uploadImage", url: "", method: "post" },

  // 添加路径轨迹
  {
    name: "pts_sux",
    url: "/v1/open/pts_sux",
    method: "post",
  },
  // 获取路径轨迹数据集合
  {
    name: "getPathTraceDataList",
    url: "/v1/open/getPathTraceDataList",
    method: "get",
  },
];
