import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/layout",
    name: "layout",
    redirect: "/",
    component: () => import("@/views/layout/index.vue"),
    children: [
      {
        path: "/",
        name: "/",
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/about",
        name: "/about",
        component: () => import("@/views/about/index.vue"),
      },
      {
        path: "/case",
        name: "/case",
        component: () => import("@/views/case/index.vue"),
      },
      {
        path: "/serve",
        name: "/serve",
        component: () => import("@/views/serve/index.vue"),
      },
      {
        path: "/listCase",
        name: "listCase",
        component: () => import("@/views/list-case/index.vue"),
      },
      {
        path: "/newsInformation",
        name: "newsInformation",
        component: () => import("@/views/news-information/index.vue"),
      },
      {
        path: "/newsInformationDetail",
        name: "newsInformationDetail",
        component: () => import("@/views/news-information-detail/index.vue"),
      },
      {
        path: "/imitatepage",
        name: "imitatepage",
        component: () => import("@/views/imitatepage/index.vue"),
      },
      {
        path: "/trialDemo",
        name: "trialDemo",
        component: () => import("@/views/demo/index.vue"),
      },
      {
        path: "/webFramework",
        name: "webFramework",
        component: () => import("@/views/web-framework/index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
