import { storeResponse } from "../utils/module";

export default {
  getters: {},
  actions: {
    // 获取新闻分类
    async fetchGetNewsCategoryList({ getters }, params) {
      const { getNewsCategoryList } = getters.getHttps;
      const result = await getNewsCategoryList(params);

      return storeResponse(result);
    },

    // 获取新闻分页列表
    async fetchGetNewsPageListReporting({ getters }, params) {
      const { getNewsPageListReporting } = getters.getHttps;
      const result = await getNewsPageListReporting(params);

      return storeResponse(result);
    },

    // 获取相邻新闻
    async fetchGetAdjacentNews({ getters }, params) {
      const { getAdjacentNews } = getters.getHttps;
      const result = await getAdjacentNews(params);

      return storeResponse(result);
    },

    // 获取新闻详情
    async fetchGetNews({ getters }, params) {
      const { getNews } = getters.getHttps;
      const result = await getNews(params);

      return storeResponse(result);
    },
    // 获取最新新闻
    async fetchGetNewNewsListReporting({ getters }, params) {
      const { getNewNewsListReporting } = getters.getHttps;
      const result = await getNewNewsListReporting(params);

      return storeResponse(result);
    },
  },
};
