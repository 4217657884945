<template>
  <div class="contact-form-container pc-wrap">
    <div class="wrapp-title">
      <div class="sub-title fontMedium">
        提交您的软件定制需求，我们将免费为您规划项目需求并输出方案！
      </div>
    </div>
    <div class="form">
      <el-form ref="formRef" :rules="clientFormRules" :model="clientForm">
        <div class="input-top">
          <el-form-item prop="contactWay">
            <ml-input
              placeholder="联系电话"
              v-model="clientForm.contactWay"
            ></ml-input>
          </el-form-item>
          <el-form-item prop="named">
            <ml-input
              placeholder="称呼姓名"
              v-model="clientForm.named"
            ></ml-input>
          </el-form-item>
        </div>

        <el-form-item :class="{ 'is-focus': textareaFocusStatus }">
          <ml-input
            @focus="onTextareaFoucs"
            @blur="onTextareaBlur"
            type="textarea"
            placeholder="留下您的需求，获取免费解决方案或报价信息（选填）"
            v-model="clientForm.demandDescribe"
          ></ml-input>
        </el-form-item>
      </el-form>

      <div class="phone-box">
        <div class="phone">
          <div class="image">
            <img :src="phoneImage" alt="" />
          </div>
          <div class="fontRegular">177-2247-6866</div>
        </div>
        <div class="notes fontRegular">
          请认真填写需求信息，我们会在10分钟内与您取得联系。
        </div>
      </div>
      <div class="btn">
        <ml-button id="form-submit" useLoading @submit="formSubmit">
          <span class="fontRegular">点击提交</span>
        </ml-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, ref, getCurrentInstance } from "vue";
import datajs from "../data.js";

const { proxy } = getCurrentInstance();

const { formRef, clientForm, clientFormRules, formSubmit } =
  datajs.apply(proxy);

// 电话
const phoneImage = require("@/assets/images/home/phone-icon.png");

// *******************************************************************************************
// 表单操作 START
// *******************************************************************************************

// 监听文本域的focus
// focus状态
const textareaFocusStatus = ref(false);
const onTextareaFoucs = () => {
  textareaFocusStatus.value = true;
};
const onTextareaBlur = () => {
  textareaFocusStatus.value = false;
};

// *******************************************************************************************
// 表单操作 END
// *******************************************************************************************
</script>

<style lang="scss" scoped>
.contact-form-container {
  &.pc-wrap {
    .wrapp-title {
      .sub-title {
        color: white;
        font-size: 30px;
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }
}
</style>

<style lang="scss">
.contact-form-container {
  &.pc-wrap {
    .form {
      width: 100%;
      max-width: 898px;
      margin: auto;

      .el-form-item {
        &.is-focus {
          border-radius: 5px;
          outline: 2px solid #2053e3;
        }
      }

      .input-top {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;

        .el-form-item {
          &:first-child {
            margin-right: 9px;
          }
          &:last-child {
            margin-left: 9px;
          }
        }

        .el-input__wrapper {
          font-size: 16px;
          height: 62px;
          border: none;
          border-radius: 5px;
          padding: 0 15px;
          box-sizing: border-box;
          outline: 2px solid white;
          transition: outline 300ms;

          &.is-focus {
            outline: 2px solid #2053e3;
          }
        }
      }

      .ml-input {
        width: 100%;
        &.textarea {
          width: 100%;
          textarea {
            width: 100%;
            border-radius: 5px;
            border: none;
            outline: none;
            resize: none;
            padding: 15px;
            box-sizing: border-box;
            font-size: 16px;
          }
        }
      }

      .phone-box {
        display: flex;
        align-items: center;
        color: white;
        margin-top: 10px;
        .phone {
          display: flex;
          align-items: center;
          cursor: pointer;
          .image {
            width: 20px;
            height: 20px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }
          div {
            font-size: 20px;
          }
        }

        .notes {
          margin-left: 40px;
        }
      }
      .btn {
        display: flex;
        justify-content: center;

        button {
          width: 160px;
          height: 48px;
          background: #ffffff;
          border: none;
          cursor: pointer;
          color: #0a41ff;
          font-size: 16px;
          font-weight: bold;
          margin-top: 70px;
          border-radius: 5px;
          transition: background 300ms;
          &:active {
            background-color: #ffffffe6;
          }
        }
      }
    }

    input::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #9b9eb3;
      font-size: 16px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9b9eb3;
      font-size: 16px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9b9eb3;
      font-size: 16px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9b9eb3;
      font-size: 16px;
    }

    textarea::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #9b9eb3;
      font-size: 16px;
    }
    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #9b9eb3;
      font-size: 16px;
    }
    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #9b9eb3;
      font-size: 16px;
    }
    textarea:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #9b9eb3;
      font-size: 16px;
    }
  }
}
</style>
