<template>
  <div class="custom-image" :class="`${className} ${size}`">
    <el-image
      @load="onImageLoad"
      @error="onImagError"
      :preview-src-list="previewUrls"
      :src="useShowUrl"
      :fit="fit"
      loading="lazy"
      :lazy="true"
      :preview-teleported="true"
      v-loading="isLoading"
    >
      <template #error>
        <div class="error-slot">
          <el-icon :size="useErrorIconSize" v-if="isEmptyDefault"
            ><Picture
          /></el-icon>
        </div>
      </template>
    </el-image>

    <slot></slot>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from "vue";
// 图标
import { Picture } from "@element-plus/icons-vue";

const props = defineProps({
  src: {
    type: String,
    default: "",
  },
  fit: {
    type: String,
    default: "cover",
  },
  className: {
    type: String,
    default: "",
  },
  errorIconSize: {
    type: [String, Number],
    default: "",
  },
  // 是否点击查看预览
  preview: {
    type: Boolean,
    default: false,
  },
  // 是否查看更多
  isMore: {
    type: Boolean,
    default: false,
  },
  // 预览列表地址
  previewList: {
    type: Array,
    default: () => [],
  },
  // 是否使用 预览前缀路径
  isPreviewBeforeUrl: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: "normal",
  },
  // 缺省类型
  emptyType: {
    type: String,
    default: "default",
  },
});

const { proxy } = getCurrentInstance();

// 使用的 缺省图标大小
const errorIconSizes = {
  small: 16,
  normal: 32,
  large: 64,
};
const useErrorIconSize = computed(() => {
  const { errorIconSize, size } = props;
  return errorIconSize ? errorIconSize : errorIconSizes[size];
});

// 缺省图片
// 默认
const isEmptyDefault = computed(() => props.emptyType == "default");

// 使用的图片地址
const useShowUrl = computed(() => {
  const { isPreviewBeforeUrl, src } = props;
  return isPreviewBeforeUrl ? `${proxy.$previewFileUrl}${src}` : src;
});

// 预览地址
const previewUrls = computed(() => {
  const { preview, isMore, previewList } = props;
  if (preview) {
    if (isMore) {
      return previewList;
    } else {
      return [useShowUrl.value];
    }
  } else {
    return [];
  }
});

/* 图片加载状态 */
// 加载状态
const isLoading = ref(true);

// 监听图片的加载
const onImageLoad = (e) => {
  isLoading.value = false;
};
// 监听图片的加载失败
const onImagError = (e) => {
  isLoading.value = false;
};
</script>

<style lang="scss" scoped>
.custom-image {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;

  &.mini {
    width: 50px;
    height: 34px;
  }

  &.small {
    width: 110px;
    height: 110px;
  }

  &.normal {
    width: 150px;
    height: 100px;
  }

  &.large {
    width: 385px;
    height: 220px;
  }

  .el-image {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #9e9e9e1c;

    .error-slot {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  //  加载 遮罩层
  ::v-deep(.el-loading-mask) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #9e9e9e1c;

    .el-loading-spinner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      top: auto;
      margin-top: 0px;

      .circular {
        width: 50%;
        height: 50%;
      }
    }
  }
}
</style>
