import { STORE_NAME_PATH_TRAJECTORY_CODE } from "@/utils/storeAgeTables";

/* 添加鼠标操作轨迹 */
export const addMousePtsSux = async function (data = []) {
  try {
    const jsonData = JSON.stringify(data);
    const code = this.$getItem(STORE_NAME_PATH_TRAJECTORY_CODE);
    const params = {
      data: jsonData,
      code,
    };
    // console.log(params)
    await this.$storeDispatch("fetchPtsSux", params);
  } catch (error) {}
};
