<template>
  <!-- 抽屉 -->
  <div class="ml-drawer">
    <!-- 侧边栏打开弹窗 -->
    <el-drawer
      :size="useSize"
      v-model="showDrawer"
      modal-class="ml-drawer-modal"
      :with-header="false"
      append-to-body
      :title="title"
    >
      <slot></slot>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

/* 属性 */
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  // 尺寸 small||normal||large
  size: {
    type: String,
    default: "normal",
  },
});

/* 尺寸 */
// 宽度
const useSize = computed(() => {
  const widths = {
    small: "300px",
    normal: "500px",
    large: "700px",
  };

  return widths[props.size] || props.size;
});

/* 抽屉窗口 */
// 开关
const showDrawer = ref(false);
// 打开
const openDrawer = () => {
  showDrawer.value = true;
};
// 关闭
const closeDrawer = () => {
  showDrawer.value = false;
};

defineExpose({
  openDrawer,
  closeDrawer,
});
</script>

<style lang="scss" scoped>
.ml-drawer-modal {
  .el-drawer__header {
    margin-bottom: 0;
    font-weight: bold;
  }
}
</style>
